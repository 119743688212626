<template>
    <div class="default-box">
        <div class="default-img">
             <span>
                 <!-- 权限不足：您没有足够的权限访问派工平台，请联系管理员。 -->
                 {{$t('visit.dispatchplatform.nopermission')}}
             </span>
            <svg class="default-svg" aria-hidden="true">
                <use href="#icon-Defaultpage"></use>
            </svg>
        </div>
    </div>
</template>


<script>
export default {
    
}
</script>



<style lang="scss" scoped>
.default-box{
    width: 100%;
    height: 100%;
    .default-img{
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: center;
        align-items: center;
        span{
            margin-top: 100px;
            font-size: 16px;
            color: #333333;
        }
        .default-svg{
            width: 600px;
            height: 400px;
            margin-left: 100px;
        }
    }
}

</style>